import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: [], // Liste des articles dans le panier
    totalQuantity: 0, // Quantité totale d'articles
    totalPrice: 0, // Prix total du panier
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        updateQuantity: (state, action) => {
            const { uniqueId, newQuantity } = action.payload;

            // Trouver l'élément correspondant
            const itemIndex = state.items.findIndex(
                (item) => item.uniqueId === uniqueId,
            );

            if (itemIndex >= 0) {
                const item = state.items[itemIndex];
                // Mise à jour du total des quantités et du prix
                state.totalQuantity += newQuantity - item.quantite;
                state.totalPrice += (newQuantity - item.quantite) * item.prix;

                // Mise à jour de la quantité de l'article
                item.quantite = newQuantity;
            }
        },
        addToCart: (state, action) => {
            const {
                type,
                productId,
                productName,
                porteGreffeId,
                porteGreffeName,
                contenantId,
                contenantName,
                quantite,
                prix,
                uniqueId,
                image,
                stock,
                productPath,
            } = action.payload;

            // Recherche d'un article existant dans le panier avec les mêmes attributs
            const existingItem = state.items.find(
                (item) => item.uniqueId === uniqueId,
            );

            if (existingItem) {
                // Augmenter la quantité si l'article existe déjà
                existingItem.quantite += quantite;
            } else {
                // Ajouter un nouvel article au panier
                state.items.push({
                    type, // Type de produit: fruitier, petits fruits, semis, pack
                    productId,
                    uniqueId,
                    productName,
                    porteGreffeId,
                    porteGreffeName,
                    contenantId,
                    contenantName,
                    quantite,
                    prix,
                    image,
                    stock,
                    productPath,
                });
            }

            // Met à jour la quantité totale et le prix total
            state.totalQuantity += quantite;
            state.totalPrice += quantite * prix;
        },
        removeFromCart: (state, action) => {
            const uniqueId = action.payload;
            console.log('🚀 ~ uniqueId:', uniqueId);

            // Trouver l'élément correspondant
            const itemIndex = state.items.findIndex(
                (item) => item.uniqueId === uniqueId,
            );
            console.log('🚀 ~ itemIndex:', itemIndex);

            if (itemIndex >= 0) {
                const item = state.items[itemIndex];
                // Mise à jour du total des quantités et du prix
                state.totalQuantity -= item.quantite;
                state.totalPrice -= item.quantite * item.prix;

                // Retirer l'article du panier
                state.items.splice(itemIndex, 1);
            }
        },
        clearCart: (state) => {
            state.items = [];
            state.totalQuantity = 0;
            state.totalPrice = 0;
        },
    },
});

export const { addToCart, removeFromCart, clearCart, updateQuantity } =
    cartSlice.actions;

export default cartSlice.reducer;
