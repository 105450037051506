module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"l":"(max-width: 1536px)","md":"(max-width: 1024px)","sm":"(max-width: 720px)","xs":"(max-width: 320px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#e0e0e0","description":"Découvrez notre pépinière paysanne, spécialisée dans les fruitiers rustiques et bio en Occitanie.","display":"minimal-ui","icon":"src/contents/images/app/logo-512.png","lang":"fr","localize":[],"name":"Les Terrasses de Perramond - Pépinière paysanne","short_name":"Les Terrasses de Perramond","start_url":"/","theme_color":"#663399","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53fdaf359ea6ef8dda4d6e80f0483d60"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
