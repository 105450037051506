import {
    createTheme,
    DEFAULT_THEME,
    mergeMantineTheme,
    rem,
} from '@mantine/core';

// project colors : #4c7766, #ebe6e0, #91736b, #fbf0f4
const themeOverride = createTheme({
    colors: {
        'main-green': [
            '#eef9f3',
            '#e2ede8',
            '#c5d7d0',
            '#a5c2b6',
            '#8aafa0',
            '#78a492',
            '#6e9e8b',
            '#5b8978', // main = 8
            '#4c7766',
            '#3e6b5a',
        ],
        'light-beige': [
            '#fbf4ee',
            '#ebe6e0', // main = 2
            '#d6cec6',
            '#c0b3a5',
            '#ad9d8a',
            '#a18f78',
            '#9d876e',
            '#89745b',
            '#7a674f',
            '#6b573f',
        ],
        taupe: [
            '#fff2eb',
            '#f0e4e2',
            '#d6c9c5',
            '#bdaba6',
            '#a8918c',
            '#9c817b',
            '#91736b', // main = 6
            '#84685f',
            '#775b54',
            '#6b4c45',
        ],
        'complementary-pink': [
            '#fbeff3', // main = 0
            '#f0dce3',
            '#e3b3c4',
            '#d689a5',
            '#cd668a',
            '#c65179',
            '#c44571',
            '#ae3760',
            '#9b2f55',
            '#892549',
        ],
        black: [
            '#eff5fa',
            '#dfe7ef',
            '#bbcfdf',
            '#93b5d1',
            '#739ec5',
            '#5f90be',
            '#5389bc',
            '#4376a6',
            '#396a95',
            '#1d405d',
        ],
        'main-orange': ['#fbf0f4', '#ef6f6c', '#c47335', '#7f534b'],
    },
    // #c47335,#EF6F6C #7f534b

    white: '#f4f2f1',
    black: '#1d405d',
    // scale: 1.125,
    defaultRadius: 'md',
    headings: {
        fontFamily: 'Pangolin, sans-serif',
        fontWeight: '400',
        sizes: {
            h2: {
                fontSize: rem(36),
            },
        },
    },
    primaryColor: 'main-green',
    primaryShade: 8,
    fontFamily: 'Nunito, sans-serif',
    breakpoints: {
        xs: '36em',
        sm: '48em',
        md: '62em',
        lg: '75em',
        xl: '88em',
    },
});

export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);
