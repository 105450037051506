import React from 'react';
import { MantineProvider } from '@mantine/core';

import '@fontsource/pangolin';
import '@fontsource-variable/nunito';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';

import './src/styles/global.styl';
import MainWrapper from './src/views/layouts/MainWrapper';
import { theme } from './src/theme';

export const wrapPageElement = ({ element }) => (
    <MainWrapper>
        <MantineProvider theme={theme}>{element}</MantineProvider>
    </MainWrapper>
);

export const onServiceWorkerUpdateReady = () => window.location.reload(true);
