import { createSlice } from '@reduxjs/toolkit';

// État initial de la slice order
const initialState = {
    order: {
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        message: '',
        client_pro: false,
        cart: [],
        totalPrice: 0,
        totalQuantity: 0,
        sent: false,
    },
    status: 'idle',
    error: null,
};

// Création de la slice order
const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrderDetails: (state, action) => {
            state.order = { ...state.order, ...action.payload };
        },
        clearOrder: (state) => {
            state.order = initialState.order;
            state.status = initialState.status;
            state.error = initialState.error;
        },
        setOrderStatus: (state, action) => {
            state.status = action.payload;
        },
        setOrderError: (state, action) => {
            state.error = action.payload;
        },
        setOrderSent: (state, action) => {
            state.order.sent = action.payload;
        },
    },
});

// Export des actions
export const {
    setOrderDetails,
    clearOrder,
    setOrderStatus,
    setOrderError,
    setOrderSent,
} = orderSlice.actions;

// Export du reducer
export default orderSlice.reducer;
